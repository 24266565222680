import './Footer.css';

function Footer(){
    return (
        <footer>
            {/* <div className="footer-categories">
            <div className="footer-item">
                <div className="footer-header">Qui suis-je ?</div>
                <div><p>Ingénieur en informatique, passionné par le développement,
                    les nouvelles technologies surtout celles émergentes.
                    J'adore également la photographie.  
                    Ce blog exprime mes opinions et doit être vu comme un recueil 
                    de mes connaissances, une sorte de cahier de notes de mon parcours de vie.</p></div>
            </div>
            <div className="footer-item">
                <div className="footer-header">Informations</div>
                <div><ul>
                    <li>Politique de confidentialité</li>
                    <li>Mentions légales</li>
                    <li>Me contacter</li>
                </ul>
                </div>              
            </div>
        </div> */}
            <div className="footer-copyright">Tous droits réservés © Guillaume Gebavi avec Technodrome.fr - 2024</div>

        </footer>
    );
}

export default Footer;