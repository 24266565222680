import './App.css';
import Navbar from './Components/Navbar/navbar';
import Main from './Components/Content/Main';
import Footer from './Components/Footer/Footer';
import { BrowserRouter as Router, Routes, Route, Link, useRoutes } from "react-router-dom";
import Article from './Components/Article/Article';
import { BsArrowUp } from 'react-icons/bs';
import ScrollToTop from './Components/ScrollToTop/ScrollToTop';
import Resume from './Components/Resume/Resume';
import { ConfigProvider } from './ConfigContext';


// import ckeditor5Dll from "ckeditor5/build/ckeditor5-dll.js";
// import ckeditor5MrkdownDll from "@ckeditor/ckeditor5-markdown-gfm/build/markdown-gfm.js";


function App() {
  return (
      <ConfigProvider>
         <Router>
           <header>
             <Navbar/>
           </header>
           <Routes>
             <Route path="/" element={<Main />} />
             <Route path="/moi" element={<Resume />} />
             <Route path="/blog" element={<Main />} />
             <Route path="/article/:id" element={<Article />} />
           </Routes>
           <ScrollToTop delay={10} scrollStepInPx={5} />
           <Footer/>
         </Router>
      </ConfigProvider>
  );
}

export default App;
