import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';


const CompactCard = ({backgroundImage, tags, title, link}) => {
    
return <div className="classic-card" style={{backgroundImage: `url(${backgroundImage})`}}>
            <div className="card-content">
                <div className="card-tag">{tags.join(" • ")}</div> 
                <div className="card-content-heading">{title}</div>
                <div className="card-button color-classic"><button><Link to={link}>Lire</Link></button></div>
            </div>
        </div>

}

export default CompactCard;