import Toggle from "react-toggle";
import { useState } from "react";
import { BsMoon } from "react-icons/bs";
import { BsSun } from "react-icons/bs";
import { useMediaQuery } from "react-responsive";
import "react-toggle/style.css"
import { useEffect } from "react";

const DarkModeToggle = () => {
  const [mode, setMode] = useState(localStorage.getItem('mode') || 'light');

  useMediaQuery(
    {query: "(prefers-color-scheme: dark)"},
    undefined,
    (isSystemDark) => setMode(isSystemDark ? "dark" : "light")
  );

  const handleClick = () =>{   
    setMode(mode === 'light' ? 'dark' : 'light')
  }

  useEffect(() => {
    if (mode == 'dark') {
      document.body.classList.add('dark');
    } else {
      document.body.classList.remove('dark');
    }
    localStorage.setItem('mode', mode); 
  }, [mode]); 

  return (
<a onClick={handleClick}>
    {mode === 'dark' ? <BsSun/> : <BsMoon/>}
</a>);

//   return (
//     <Toggle
//       checked={isDark}
//       onChange={({target}) => setIsDark(target.checked)}
//       icons={{checked: "🌙", unchecked: "🔆"}}
//       aria-label="Mode jour ou mode nuit"
//     />
//   );
};

export default DarkModeToggle;