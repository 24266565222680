import './Navbar.css'
import { Link } from 'react-router-dom';
//import logo from './logo_transparent.png';
import logo from './Logo_Technodrome_v2024-4.png';
import { CiMail } from 'react-icons/ci';
import { BsMoon, BsSun } from 'react-icons/bs';
import { useState, useEffect, useRef } from "react";
import { MdClose } from 'react-icons/md';
import { FiMenu } from 'react-icons/fi';
import DarkModeToggle from '../DarkModeToggle/DarkModeToggle';

function Navbar() {
   

    const [navbarOpen, setNavbarOpen] = useState(false);

    const ref = useRef();
  useEffect(() => {
    const handler = (event) => {
      if (
        navbarOpen &&
        ref.current &&
        !ref.current.contains(event.target)
      ) {
        setNavbarOpen(false);
      }
    };
    document.addEventListener('mousedown', handler);
    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', handler);
    };
  }, [navbarOpen]);

    return (
        <nav ref={ref}>
            <div className="header-mobile">
                <button className="toggle-menu" onClick={() => setNavbarOpen((prev) => !prev)}>
                    {navbarOpen ? <MdClose/> : <FiMenu/>}
                </button>
                <ul className={`menu-mobile ${navbarOpen ? ' show-menu' : ''}`}>
                        <li><Link to="/articles" onClick={() => setNavbarOpen(false)}> Blog</Link></li>
                        <li><Link to="/projets" onClick={() => setNavbarOpen(false)}> Projets</Link></li>
                        <li><Link to="/moi" onClick={() => setNavbarOpen(false)}> Qui suis-je</Link></li>
                        <li><Link to="/contact" onClick={() => setNavbarOpen(false)}> Contact</Link></li>
                </ul>
                <div className="mode"><DarkModeToggle/></div>
                <div className="logo"><Link to="/"><img src={logo} alt=""/></Link></div>
            </div>
            <div className="header">
                <div className="logo"><Link to="/"><img src={logo} alt=""/></Link></div>
                <ul className="menu">
                    <li><Link to="/blog"> Blog</Link></li>
                    <li><Link to="/projets"> Projets</Link></li>
                    <li><Link to="/moi"> Qui suis-je ?</Link></li>
                </ul>               
                <ul className="tools">
                    <li><Link to="/contact"> <CiMail/></Link></li>
                    <li><DarkModeToggle/></li>

                </ul>
            </div>
        </nav>
    );
  }
  
  export default Navbar;