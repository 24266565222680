const Resume = () => {

   return (
   <div className="resume-container">

        <div className="resume-header">
            <div className="resume-header-name">Guillaume Gebavi</div>

            <div className="resume-header-description">Ingénieur en informatique, passionné par le développement,
                les nouvelles technologies surtout celles émergentes.
                    J'adore également la photographie.</div>
                    <div className="resume-header-location">Lyon, France</div>

            <div className="resume-header-logos">logos, logos, logos, logos</div>
        </div>

        <div className="resume-about">
            <div className="resume-about-title">A propos</div>
            <div className="resume-about-content">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolor repellendus quis maxime sed officiis pariatur qui, laborum quod dicta repellat ab reprehenderit doloribus ex explicabo veniam ad? Blanditiis, quas ad.
            </div>
        </div>

        <div className="resume-experience">
            <div className="resume-experience-title">Expérience</div>
            <div className="resume-experience-name">SCC France</div>
            <div className="resume-experience-description">Ingénieur .NET blablablabla</div>
        </div>

        <div className="resume-education">
            <div className="resume-education-title">Formation</div>
            <div className="resume-education-name">CPE Lyon</div>
            <div className="resume-education-description">Ingénieur informatique</div>
        </div>

        <div className="resume-skills">
            <div className="resume-skills-title">Compétences</div>
            <div className="resume-skills-content">
                <div className="resume-skills-content-item">C#</div>
                <div className="resume-skills-content-item">.NET</div>
                <div className="resume-skills-content-item">React</div>
                <div className="resume-skills-content-item">Angular</div>
                <div className="resume-skills-content-item">NodeJS</div>
                <div className="resume-skills-content-item">SQL</div>
                <div className="resume-skills-content-item">NoSQL</div>
                <div className="resume-skills-content-item">MongoDB</div>
                <div className="resume-skills-content-item">Azure</div>
                <div className="resume-skills-content-item">AWS</div>
                <div className="resume-skills-content-item">Docker</div>
                <div className="resume-skills-content-item">Kubernetes</div>
                <div className="resume-skills-content-item">Git</div>
                <div className="resume-skills-content-item">GitHub</div>
                <div className="resume-skills-content-item">GitLab</div>
                <div className="resume-skills-content-item">Jenkins</div>
                <div className="resume-skills-content-item">Azure DevOps</div>
                <div className="resume-skills-content-item">Terraform</div>
                <div className="resume-skills-content-item">Ansible</div>
                <div className="resume-skills-content-item">Powershell</div>
                <div className="resume-skills-content-item">Bash</div>
                <div className="resume-skills-content-item">Linux</div>
                <div className="resume-skills-content-item">Windows</div>
                <div className="resume-skills-content-item">MacOS</div>
                <div className="resume-skills-content-item">Docker</div>
                <div className="resume-skills-content-item">Kubernetes</div>
                <div className="resume-skills-content-item">Git</div>
                <div className="resume-skills-content-item">GitHub</div>
                <div className="resume-skills-content-item">GitLab</div>
                <div className="resume-skills-content-item">Jenkins</div>
            </div>
        </div>

        <div className="resume-projects">
            <div className="resume-projects-title">Projets</div>
            <div className="resume-projects-card">
                <div className="resume-projects-card-item">
                    <div className="resume-projects-card-item-title">Technodrome.fr</div>
                    <div className="resume-projects-card-item-description">Blog personnel</div>
                    <div className="resume-projects-card-item-badges">
                        <span>Side project</span>
                        <span>TypeScript</span>
                        <span>Vite</span>
                        <span>WebRTC</span>
                    </div>
                </div>
                <div className="resume-projects-card-item">
                    <div className="resume-projects-card-item-title">Technodrome.fr</div>
                    <div className="resume-projects-card-item-description">Blog personnel</div>
                    <div className="resume-projects-card-item-badges">
                        <span>Side project</span>
                        <span>TypeScript</span>
                        <span>Vite</span>
                        <span>WebRTC</span>
                    </div>
                </div>
                <div className="resume-projects-card-item">
                    <div className="resume-projects-card-item-title">Technodrome.fr</div>
                    <div className="resume-projects-card-item-description">Blog personnel</div>
                    <div className="resume-projects-card-item-badges">
                        <span>Side project</span>
                        <span>TypeScript</span>
                        <span>Vite</span>
                        <span>WebRTC</span>
                    </div>
                </div>
                <div className="resume-projects-card-item">
                    <div className="resume-projects-card-item-title">Technodrome.fr</div>
                    <div className="resume-projects-card-item-description">Blog personnel</div>
                    <div className="resume-projects-card-item-badges">
                        <span>Side project</span>
                        <span>TypeScript</span>
                        <span>Vite</span>
                        <span>WebRTC</span>
                    </div>
                </div>
                <div className="resume-projects-card-item">
                    <div className="resume-projects-card-item-title">Technodrome.fr</div>
                    <div className="resume-projects-card-item-description">Blog personnel</div>
                    <div className="resume-projects-card-item-badges">
                        <span>Side project</span>
                        <span>TypeScript</span>
                        <span>Vite</span>
                        <span>WebRTC</span>
                    </div>
                </div>
            </div>
        </div>

   </div>


   
   );
}
export default Resume;
