/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react';
import './Article.css';
import '../Content/Main.css';
import { useParams } from 'react-router-dom'
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm'
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { tomorrow, okaidia, coldarkDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { BsBook } from 'react-icons/bs';
import { css } from '@emotion/react';
import config from '../../config.json';
import { mdtocs } from 'mdtocs';
import rehypeSlug from 'rehype-slug';
import rehypeAutolinkHeadings from 'rehype-autolink-headings';
import {useConfig} from "../../ConfigContext";
import useApi from "../../Api/useApi";

function Article(){
    const { id } = useParams();
    const { apiFullUrl } = useConfig();
    const { fetchData } = useApi();

    //const [posts, setPosts] = useState([])
    const [title, setTitle] = useState("Chargement de la page ...")
    const [content, setContent] = useState("content")
    const [publishDate, setPublishDate] = useState("Publish Date")
    const [description, setDescription] = useState("Description")
    const [author, setAuthor] = useState("Auteur")
    const [tags, setTags] = useState([])
    const [slug, setSlug] = useState("Slug")
    const [banner, setBanner] = useState("Banner")
    const [twitterLogo, setTwitterLogo] = useState(apiFullUrl + "/uploads/logo_white_ad3e56a20a.webp");
    const [linkedinLogo, setLinkedinLogo] = useState(apiFullUrl + "/uploads/linkedin_20_fd207b8515.webp");
    const [metaLogo, setMetaLogo] = useState(apiFullUrl + "/uploads/facebook_60720a12e4.png");

    useEffect(() => {

        const fetchPost = async () => {
            //const data = await fetchPostsBySlug(id);
            const data = await fetchData(`/api/posts?populate=*&filters[Slug][$eq]=${id}`);
            //
            setTitle(data.data[0].attributes.Title);

            const date = new Date(data.data[0].attributes.publishedAt)
            const formattedDate = date.toLocaleDateString("fr-FR", {
              day: "numeric",
              month: "long",
              year: "numeric"
            })

            setPublishDate(formattedDate);
            setContent(data.data[0].attributes.Content);
            setDescription(data.data[0].attributes.Description)
            setAuthor(data.data[0].attributes.Author)
            setTags(data.data[0].attributes.categories.data.map(x => x.attributes.Title))
            setSlug(data.data[0].attributes.Slug)
            setBanner(data.data[0].attributes.Banner?.data?.attributes?.url)
            // console.log(data.data[0].attributes);
        }

        fetchPost();
    }, []);

    return (
        <main>
            <section className="article"
            css={css`&::before {
        background-image: radial-gradient(circle farthest-side, var(--color-background-overlay) 0%, var(--color-background) 80% ,var(--color-background) 100%),url("${config.BLOG_API_BASE_URL}${banner}");
      }
    `}
    >

            <div className="header-article">
              <div className="tags-article">{tags.map(x => <span>{x}</span>)}</div>
              <div className="title-article">{title}</div>
              <div className='published'>
                  <div className='avatar'></div>
                  <div className='author'>
                      <div className='author-name'>{author}</div>
                      <div className='author-role'>Software Engineer</div>
                  </div>
                  <div className="meta-info">
                      <div>le {publishDate} &nbsp;</div>
                      {/*<div><BsBook></BsBook> &nbsp;8 minutes</div>*/}
                  </div>
                  <div className="socials">
                      <div className="icon meta" css={css`background:url("${metaLogo}");`}></div>
                      <div className="icon twitter" css={css`background:url("${twitterLogo}");`}></div>
                      <div className="icon linkedin" css={css`background:url("${linkedinLogo}");`}></div>
                  </div>
              </div>
            </div>

                <div className='container-article'>
                    {/*<div className='meta-article'>*/}
                    {/*  <div className='description'>{description}</div> */}
                    {/*</div>*/}
                    <div className="banner-article"
                         style={{backgroundImage: 'url("' + apiFullUrl + banner + '")'}}></div>
                    <div className='sidebar-article'>
                        <div id='sidebar-toc'>
                            {/*<p>{}</p>*/}

                            <ReactMarkdown
                                children={mdtocs(content)}
                            />
                        </div>
                    </div>

                    <div className="content-article">
                        <ReactMarkdown
                            children={content}
                            remarkPlugins={[remarkGfm]}
                            rehypePlugins={[rehypeSlug, rehypeAutolinkHeadings]}
                            components={{
                                code({node, inline, className, children, ...props}) {
                                    const match = /language-(\w+)/.exec(className || "");
                                    return !inline && match ? (
                                        <SyntaxHighlighter
                                            children={String(children).replace(/\n$/, "")}
                                            language={match[1]}
                                            showLineNumbers={true}
                                            showInlineLineNumbers={true}
                                            style={coldarkDark}
                                            wrapLongLines={true}
                                            {...props}
                                        />
                                    ) : (
                                        <code className={className} {...props}>
                                            {children}
                                        </code>
                                    );
                                },
                            }}
                        />

                    </div>
                </div>
            </section>


        </main>
    );
}

export default Article;