import { Link } from 'react-router-dom';
import './GridCards.css';
import { BsBook, BsEyeglasses, BsBoxArrowInUpRight  } from 'react-icons/bs';
import { useEffect, useState } from 'react';
import CompactCard from '../CompactCard/CompactCard';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
//import 'react-tabs/style/react-tabs.css';
import { useConfig } from '../../../ConfigContext';
import useApi from "../../../Api/useApi";


const GridCards = () => {

    const { fetchData } = useApi();
    const [title, setTitle] = useState("Chargement de la page ...")
    const [content, setContent] = useState("content")
    const [publishDate, setPublishDate] = useState("Publish Date")
    const [description, setDescription] = useState("Description")
    const [author, setAuthor] = useState("Auteur")
    const [tags, setTags] = useState([])
    const [slug, setSlug] = useState("Slug")
    const [url, setUrl] = useState("Url")
    const [banner, setBanner] = useState("Banner")
    const [otherArticles, setOtherArticles] = useState([])
    const [articlesLength, setArticlesLength] = useState(0)

    const [categories, setCategories] = useState([])

    const { apiFullUrl } = useConfig();

    useEffect(() => {

        const fetchPosts = async () => {
            const data = await fetchData(`/api/posts?populate=*&fields[0]=Title&fields[1]=Description&fields[2]=Slug&fields[3]=publishedAt`);
            setTitle(data.data[0].attributes.Title);

            const date = new Date(data.data[0].attributes.publishedAt)
            const formattedDate = date.toLocaleDateString("fr-FR", {
              day: "numeric",
              month: "long",
              year: "numeric"
            })

            setPublishDate(formattedDate);
            setDescription(data.data[0].attributes.Description)
            setAuthor(data.data[0].attributes.Author)
            setTags(data.data[0].attributes.categories.data.map(x => x.attributes.Title))
            setSlug(data.data[0].attributes.Slug)
            setUrl("/article/"+data.data[0].attributes.Slug)
            setBanner(apiFullUrl+data.data[0].attributes.Banner?.data?.attributes?.url)
            setArticlesLength(data.data.length)
            setOtherArticles(data.data.slice(1))
        }

        const fetchCategories = async () => {

            const data = await fetchData('/api/categories?fields[0]=Title');
            const categoriesPromises = data.data.map(async x => {
                const categoryName = x.attributes.Title;
                //const posts = await fetchAllPostsCompactByCategory(categoryName);
                const posts = await fetchData(`/api/posts?fields[0]=Title&fields[1]=Description&fields[2]=Slug&fields[3]=publishedAt&filters[categories][Title][$eq]=${categoryName}&populate[Banner][fields][0]=url&populate[categories][fields][0]=Title`);


                return {
                    title: categoryName,
                    articles: posts.data.map(post => {
                        const date = new Date(post.attributes.publishedAt)
                        const formattedDate = date.toLocaleDateString("fr-FR", {
                            day: "numeric",
                            month: "long",
                            year: "numeric"
                        });
                        return ({
                            title: post.attributes.Title,
                            published: formattedDate,
                            description: post.attributes.Description,
                            slug: post.attributes.Slug,
                            banner: post.attributes.Banner.data.attributes.url,
                            tags: post.attributes.categories.data.map(x => x.attributes.Title)
                        });
                    })
                };
            });

            // Utilisez Promise.all pour attendre toutes les promesses avant de continuer
            const categories = await Promise.all(categoriesPromises);

            // Maintenant, categories est un tableau d'objets, pas de promesses
            setCategories(categories);
        }

        fetchPosts();
        fetchCategories();

    }, [fetchData]);

    return (
        <section className="container-top-articles">
            <div className="title"><span>Dernier article 🚀</span></div>
            <div className="card-wrapper">
                <div className="card card-top">
                    <div className="card-image">
                        <img src={banner} alt="Image Description" />
                    </div>
                    <div className="card-content">
                        <div className="card-content-heading">
                            <div className="heading-title">{title}</div>
                            <div className="heading-published">
                                Publié le {publishDate} dans {tags.join("•")}
                            </div>
                        </div>
                        <div className="card-content-description">{description}</div>
                        <div className="card-button color-top"><button><Link to={url}>Lire l'article <div>API URL
                            : {`${apiFullUrl}`}</div> &nbsp;<BsBoxArrowInUpRight/></Link></button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="title"><span>Autres articles</span></div>

            <Tabs>
                <TabList>
                    {categories.map((item, index) => (
                        <Tab>{item.title}</Tab>
                    ))}
                </TabList>

                {categories.map((article, index) => (
                    <TabPanel>
                        <div className="other-articles">
                            {article.articles.map((item, index) => (

                                 <div className="individual-article">
                                     <div className="card-presentation">
                                         <CompactCard
                                             backgroundImage={apiFullUrl + item.banner}
                                             tags={item.tags}
                                             title={item.title}
                                             link={"/article/"+item.slug}>
                                         </CompactCard>
                                     </div>
                                     <div className="other-article-content">
                                         <span className="other-article-published">Publié le {item.published}</span>
                                         <span className="other-article-description">{item.description}</span>
                                     </div>
                                 </div>

                                // <div className="individual-article">
                                //     <div className="card-presentation" style={{backgroundImage: 'url("' + config.BLOG_API_BASE_URL + item.banner + '")'}}>{item.title}</div>
                                //     <div className="description"></div>
                                // </div>
                            ))}
                        </div>
                    </TabPanel>
                ))}


            </Tabs>

            {/* <div className="subtitle">Derniers articles sur des sujets de développement, d'informatique et d'internet</div> */}
            {/*<div className="grid-cards">*/}
            {/*    {otherArticles.map((article, index) => (*/}
            {/*        <div key={index}>*/}
            {/*                 <CompactCard*/}
            {/*                 backgroundImage={config.BLOG_API_BASE_URL + article.attributes.Banner?.data?.attributes?.url}*/}
            {/*                 tags={article.attributes.categories.data.map(x => x.attributes.Title)}*/}
            {/*                 title={article.attributes.Title}*/}
            {/*                 link={"/article/"+article.attributes.Slug}>*/}
            {/*             </CompactCard></div>*/}
            {/*    ))}*/}
            {/*</div>*/}
        </section>

    );
}

export default GridCards;