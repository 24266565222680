import './scroll-to-top.css';
import { useState, useEffect } from 'react';
import { BsArrowUp } from 'react-icons/bs';
///
/// from "https://fmisery.fr/bouton-scroll-to-top-avec-reactjs-et-hooks/"
const ScrollToTop = ({scrollStepInPx, delay}) => {

    // Le state "scroll" permet de savoir si un scroll est en cours
    // La méthode setScroll permet de modifier cet état, 
    // => le rendu de ce composant 
    // => l'exécution ou non du scroll
    const [scroll, setScroll] = useState(false);

    const [scrollStyle, setScrollStyle] = useState({
        transition: "all .3s ease-in-out",
        opacity: "0",
        visibility: "hidden"
    });
    
    // Fonction chargée du scroll vers le haut
    const scrollStep = () => {
        if (window.pageYOffset === 0) {
            // Le scroll est terminé, 
            // on utilise  intervalId pour arrêter la répétition
            clearInterval(intervalId);
            // on repasse le state "scoll" à false
            setScroll(false);
        }
        window.scroll(0, window.pageYOffset - scrollStepInPx);
    };

    const handleScroll = () => {
        if (window.pageYOffset > 300) {
            setScrollStyle({transition: "all .3s ease-in-out", opacity: "1", visibility: "visible"});      
        } else {
            setScrollStyle({transition: "all .3s ease-in-out", opacity: "0", visibility: "hidden"});
        } 
        console.log("test");
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    
    // Si un scroll doit s'effectuer, 
    // on lance la fonction scrollStep de façon répétée avec setInterval
    // On stocke l'id correspondant dans intervalId
    // Sinon, intervalId reste indéfini
    const intervalId = scroll ? setInterval(scrollStep, delay) : undefined;

    return (
    <div id="scroll-to-top" style={scrollStyle} onClick={e => scroll || setScroll(true)}> 
        <BsArrowUp/>
    </div>);
}

export default ScrollToTop;