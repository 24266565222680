import React, { createContext, useContext } from 'react';

const ConfigContext = createContext(null);

export const ConfigProvider = ({ children }) => {
    const config = {
        apiUrl: process.env.REACT_APP_API_URL,
        apiPort: process.env.REACT_APP_API_PORT,
        apiFullUrl: `${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}`,
        apiToken: process.env.REACT_APP_API_TOKEN,
        apiUploadDir: process.env.REACT_APP_API_UPLOAD_DIR
    };

    return (
        <ConfigContext.Provider value={config}>
            {children}
        </ConfigContext.Provider>
    );
};

export const useConfig = () => useContext(ConfigContext);