import './Main.css';
import Hero from '../../Components/Hero/hero'
import GridCards from './GridCards/GridCards'

function Main(){
    return (
        <>
        {/* <Hero/> */}
        <main>
        <GridCards/>
      
    </main>
    </>
    );
}

export default Main;